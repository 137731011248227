import React from "react";
import { Link } from "gatsby";


import blogTemplateStyles from "./blogTemplate.module.css";
import Head from "../components/head";


import { graphql } from "gatsby";

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      slug
      blogImage {
        file {
          url
        }
      }
      author
      publishDate(formatString: "MMMM DD, YYYY")
      title
      blogEntry {
        blogEntry
      }
      authorImage {
        file {
          url
        }
      }
    }
  }
`;

class blogTemplate extends React.Component {
  componentDidMount() {
    Array.from(document.querySelectorAll("p")).forEach(el => {
      let v = el.innerHTML;
      // var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
      var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
      let link = v.replace(urlRegex, function(url) {
        return '<a href="' + url + '">' + url + "</a>";
      });
      el.innerHTML = link;
    });
  }
  render() {
    return (
      <>
      <Head title={this.props.data.contentfulBlogPost.slug}></Head>
        <div className={blogTemplateStyles.blogPostContainer}>
          <h1>{this.props.data.contentfulBlogPost.title}</h1>
          <h3>{this.props.data.contentfulBlogPost.author}</h3>
          <p>{this.props.data.contentfulBlogPost.publishDate}</p>
          <div className={blogTemplateStyles.blogBGImageContainer}>
            <img
              className={blogTemplateStyles.blogPostImg}
              src={this.props.data.contentfulBlogPost.blogImage.file.url}
            ></img>
            <img
              className={blogTemplateStyles.authorBlogImg}
              src={this.props.data.contentfulBlogPost.authorImage.file.url}
            />
          </div>
          <div className={blogTemplateStyles.blogPostCopyContainer}>
            <p style={{ whiteSpace: "pre-wrap" }}>
              {this.props.data.contentfulBlogPost.blogEntry.blogEntry}
            </p>

            <div className={blogTemplateStyles.emailUs}>
              If you have any questions or concerns email us at{" "}
              <Link to="/contact-us">admin@thesecretsits.com</Link>{" "}
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default blogTemplate;
